// index.js

import ExtendedPropertyProvider from "./ExtendedPropertyProvider";
import { FeelPopupModule } from "@bpmn-io/properties-panel";

export default {
  __depends__: [FeelPopupModule],
  __init__: ["extendedPropertiesProvider"],
  extendedPropertiesProvider: ["type", ExtendedPropertyProvider],
};
