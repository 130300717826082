import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./../../_assets/scss/sidebar.scss";
import HomeIcon from "@mui/icons-material/Home";
import { withTranslation } from "react-i18next";
import ListAltIcon from "@mui/icons-material/ListAlt";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      isScrolled: false,
    };
  }

  LINKS = [
    { url: "/postlogin/dashboard", active: 0 },
    { url: "/postlogin/surveys", active: 1 },
    { url: "/postlogin/questionnaires", active: 1 },
    { url: "/postlogin/answers", active: 1 },
    { url: "/postlogin/summary", active: 1 },
  ];

  componentDidMount() {
    const { history } = this.props;
    this.historyListener = history.listen((location) => {
      this.changeActiveTab(location);
    });
    this.changeActiveTab(this.props.location);
  }

  changeActiveTab = (location) => {
    const pathname = location.pathname;
    for (let i = 0; i < this.LINKS.length; i++) {
      if (pathname.indexOf(this.LINKS[i].url) !== -1) {
        this.setState({
          activeTab: this.LINKS[i].active,
        });
        break;
      }
    }
  };

  componentWillUnmount() {
    if (this.historyListener) {
      this.historyListener();
    }
  }

  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    // Toggle 'scrolled' class after 100px of scrolling
    if (scrollPosition > 50) {
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  };

  render() {
    const { activeTab, isScrolled } = this.state;
    const { t } = this.props;
    return (
      <div className={isScrolled ? "sidebar scrolled" : "sidebar"}>
        <ul className="d-block w-100 sidebar-menu">
          <li
            className={activeTab === 0 ? "active-page" : ""}
            onClick={(e) => this.setActiveTab(0)}
          >
            <Link to="/postlogin/dashboard" className="d-flex">
              <HomeIcon fontSize="medium" />
              <span className="d-inline-flex ml-2">{t("Home")}</span>
            </Link>
          </li>

          <li
            className={activeTab === 1 ? "active-page" : ""}
            onClick={(e) => this.setActiveTab(1)}
          >
            <Link to="/postlogin/surveys" className="d-flex">
              <ListAltIcon fontSize="medium" />
              <span className="d-inline-flex ml-2">{t("Surveys")}</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const connectedSidebar = withTranslation()(connect(mapStateToProps)(Sidebar));
export { connectedSidebar as Sidebar };
