import React, { Component, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "../_components";
import { LoginLayout, DefaultLayout } from "../Layout";
import "../_assets/scss/common.scss";
import { commonFunctions } from "_utilities";
import RespondentsTearmsAndConditons from "PreLogin/RespondentsTearmsAndConditons";

function App() {
  const [token, setToken] = useState(commonFunctions.getAccessToken());

  useEffect(() => {
    setToken(commonFunctions.getAccessToken());
  });

  if (token) {
    return (
      <BrowserRouter>
        <Suspense fallback={() => <Loading />}>
          <Switch>
            <Route path="/postlogin/*" component={DefaultLayout} />
            <Route path="/survey/*" component={DefaultLayout} />
            <Route path="/preview/*" component={DefaultLayout} />
            <Route
              path="/tearms-and-conditons"
              component={RespondentsTearmsAndConditons}
            />
            <Route
              path="*"
              render={() => {
                return <Redirect to="/postlogin/dashboard" />;
              }}
            />
          </Switch>
        </Suspense>
        <ToastContainer
          enableMultiContainer
          containerId={"TOP_RIGHT"}
          position={toast.POSITION.TOP_RIGHT}
        />
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Suspense fallback={() => <Loading />}>
          <Switch>
            <Route path="/prelogin/*" component={LoginLayout} />
            <Route
              path="/tearms-and-conditons"
              component={RespondentsTearmsAndConditons}
            />
            <Route
              path="*"
              render={() => {
                return <Redirect to="/prelogin/login" />;
              }}
            />
          </Switch>
        </Suspense>
        <ToastContainer
          enableMultiContainer
          containerId={"TOP_RIGHT"}
          position={toast.POSITION.TOP_RIGHT}
        />
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp;
