import React, { useEffect, useRef } from "react";
import "../_assets/scss/bpmn.scss";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "@bpmn-io/properties-panel/assets/properties-panel.css";
import BpmnModeler from "bpmn-js/lib/Modeler";
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
} from "bpmn-js-properties-panel";
import extendedPropertiesProviderModule from "./provider/extended-property-panel";
import magicModdleDescriptor from "./descriptors/magic.json";
import transactionBoundariesModule from "./provider/transaction-boundries-provider";
import customizeDiagramModule from "./provider/customizeDiagramProvider";
import { componentType } from "_constants";
import { alert } from "_utilities";
import { ButtonLoader } from "_components";

// const jsonData = {
//   process: {
//     id: "Process_1",
//     name: "Example Process",
//     tasks: [
//       { id: "Task_1", name: "Start Task" },
//       { id: "Task_2", name: "Intermediate Task" },
//       { id: "Task_3", name: "End Task" },
//     ],
//     flows: [
//       { id: "Flow_1", source: "Task_1", target: "Task_2" },
//       { id: "Flow_2", source: "Task_2", target: "Task_3" },
//     ],
//   },
// };
function parseRange(rangeStr) {
  const [min, max] = rangeStr.split("-").map(Number);
  return { min, max };
}

function validateRanges(ranges) {
  let isValid = true;
  let message = "";
  ranges.forEach((rangeStr, index) => {
    const { min, max } = parseRange(rangeStr);

    if (min > max) {
      console.log(`Invalid range: ${rangeStr} (min is greater than max)`);
      message = `Invalid range: (min is greater than max)`;
      isValid = false;
    }

    for (let i = index + 1; i < ranges.length; i++) {
      const { min: otherMin, max: otherMax } = parseRange(ranges[i]);
      if (max >= otherMin && min <= otherMax) {
        console.log(
          `Overlap detected between ranges: ${rangeStr} and ${ranges[i]}`
        );
        message = "Overlap detected between ranges";
        isValid = false;
      }
    }
  });

  return { isValid, message };
}

const BpmnModelerComponent = (props) => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const propertiesPanelRef = useRef(null);
  const downloadLinkRef = useRef(null);

  useEffect(() => {
    let bpmnModeler = null;
    if (props?.data) {
      bpmnModeler = new BpmnModeler({
        container: "#js-canvas",
        keyboard: { bindTo: document },
        propertiesPanel: {
          parent: "#js-properties-panel",
        },
        additionalModules: [
          BpmnPropertiesPanelModule,
          BpmnPropertiesProviderModule,
          extendedPropertiesProviderModule,
          transactionBoundariesModule,
          customizeDiagramModule,
        ],
        moddleExtensions: {
          magic: magicModdleDescriptor,
        },
      });

      const openDiagram = async (xml) => {
        try {
          await bpmnModeler.importXML(xml);
          var transactionBoundaries = bpmnModeler.get("transactionBoundaries");
          transactionBoundaries.show();
        } catch (err) {
          console.error(err);
        }
      };

      const convertXmlToJson = async (xml) => {
        try {
          const elementRegistry = bpmnModeler.get("elementRegistry");
          const allElements = elementRegistry.getAll();

          const dataSourceElements = allElements.filter((element) => {
            return (
              element.businessObject.$attrs && element.businessObject.$attrs
            );
          });

          const dataSourceValues = dataSourceElements.map((element) => ({
            id: element.id,
            name: element.businessObject.name,
            type: element.businessObject.$attrs["type"],
            selectedValue: element.businessObject.$attrs["selectedValue"],
            sourceRef: element.businessObject.sourceRef,
            targetRef: element.businessObject.targetRef,
          }));

          let questions = dataSourceValues.filter(
            (element) => !element.sourceRef && !element.targetRef
          );

          let flows = dataSourceValues.filter(
            (element) =>
              element.sourceRef && element.targetRef && element.selectedValue
          );

          let obj = {};
          let duplicateElemetnsNames = [];
          let isRangeValid = true;
          let rangeMessage = "";

          if (flows?.length) {
            let arrLineRate = [componentType.LINESCALE, componentType.RATE];

            flows.forEach((element) => {
              obj[element.sourceRef.id] = obj[element.sourceRef.id]
                ? obj[element.sourceRef.id]
                : {};
              questions = questions.map((question) => {
                if (element.sourceRef.id === question.id) {
                  const trueKeys = Object.keys(element.selectedValue).filter(
                    (key) => {
                      if (
                        element.selectedValue[key] === true &&
                        !arrLineRate.includes(question.type)
                      ) {
                        return key;
                      }
                    }
                  );
                  obj[question.id]["name"] = question.name;
                  obj[question.id]["type"] = question.type;
                  obj[question.id]["showNext"] = obj[question.id]["showNext"]
                    ? [
                        ...obj[question.id]["showNext"],
                        {
                          id: element?.targetRef?.id?.replace("Question_", ""),
                          opts: arrLineRate.includes(question.type)
                            ? [
                                `${
                                  element.selectedValue[`${question.type}_from`]
                                }-${
                                  element.selectedValue[`${question.type}_to`]
                                }`,
                              ]
                            : trueKeys,
                        },
                      ]
                    : [
                        {
                          id: element?.targetRef?.id?.replace("Question_", ""),
                          opts: arrLineRate.includes(question.type)
                            ? [
                                `${
                                  element.selectedValue[`${question.type}_from`]
                                }-${
                                  element.selectedValue[`${question.type}_to`]
                                }`,
                              ]
                            : trueKeys,
                        },
                      ];
                }
                return question;
              });
            });

            let keys = Object.keys(obj);

            if (keys.length) {
              keys.forEach((key) => {
                let checkedValues = obj[key].showNext;
                let rangeValues = [];
                if (arrLineRate.includes(obj[key].type)) {
                }
                if (checkedValues) {
                  let allOpts = [];
                  checkedValues = checkedValues.map((value) => {
                    if (value.opts.length) {
                      value.opts.forEach((opt) => {
                        rangeValues.push(opt);
                        if (allOpts.includes(opt)) {
                          if (!duplicateElemetnsNames.includes(obj[key].name)) {
                            duplicateElemetnsNames.push(obj[key].name);
                          }
                        } else {
                          allOpts.push(opt);
                        }
                      });
                      if (rangeValues?.length) {
                        let { isValid, message } = validateRanges(rangeValues);
                        isRangeValid = isValid;
                        rangeMessage = message;
                      }
                    }
                  });
                }
              });
            }

            if (duplicateElemetnsNames.length) {
              alert.error(
                `"${duplicateElemetnsNames.join(
                  ","
                )}" selected value should be unique${
                  !isRangeValid ? ` and ${rangeMessage}` : ""
                }.`
              );
            } else if (!isRangeValid) {
              alert.error("Rating or line scale " + rangeMessage);
            } else {
              props.xmlToJsonData(obj);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };

      openDiagram(props?.data);
      var commandStack = bpmnModeler.get("commandStack");
      var canvas = bpmnModeler.get("canvas");

      // Add event listeners to buttons for undo redo
      document.getElementById("undoBtn").addEventListener("click", () => {
        commandStack.undo();
      });
      document.getElementById("redoBtn").addEventListener("click", () => {
        commandStack.redo();
      });

      // Add event listeners to buttons for zoom-in, zoom-out, reset-zoom
      document.getElementById("zoomInBtn").addEventListener("click", () => {
        canvas.zoom(canvas.zoom() + 0.1);
      });
      document.getElementById("zoomOutBtn").addEventListener("click", () => {
        canvas.zoom(canvas.zoom() - 0.1);
      });
      document.getElementById("resetZoomBtn").addEventListener("click", () => {
        canvas.zoom("fit-viewport");
      });
      document.getElementById("xmlToJson").addEventListener("click", () => {
        convertXmlToJson();
      });
    }

    return () => {
      if (bpmnModeler) {
        bpmnModeler.destroy();
      }
    };
  }, []);

  return (
    <div ref={containerRef} id="js-drop-zone">
      <div
        className="canvas"
        ref={canvasRef}
        id="js-canvas"
        style={{ height: "100vh" }}
      ></div>
      <div id="js-properties-panel" ref={propertiesPanelRef}>
        <div id="resize-divider"></div>
      </div>
      <ul className="buttons">
        <li>
          <button id="undoBtn">Undo</button>
        </li>
        <li>
          <button id="redoBtn">Redo</button>
        </li>
        <li>
          <button id="zoomInBtn">Zoom In</button>
        </li>
        <li>
          <button id="zoomOutBtn">Zoom Out</button>
        </li>
        <li>
          <button id="resetZoomBtn">reset Zoom</button>
        </li>
        <li>
          <button id="xmlToJson" disabled={props.isLoading}>
            {props.isLoading ? <ButtonLoader /> : "XML To JSON Save"}{" "}
          </button>
        </li>
      </ul>
      <>
        <div
          className="modal micromodal-slide"
          id="editor-modal"
          aria-hidden="true"
        >
          <div
            className="modal__overlay"
            tabIndex={-1}
            data-micromodal-close=""
          >
            <div
              className="modal__container"
              role="dialog"
              aria-modal="true"
              aria-labelledby="editor-modal-title"
            >
              <header className="modal__header">
                <h2 className="modal__title" id="editor-modal-title">
                  Micromodal
                </h2>
                <button
                  className="modal__close"
                  aria-label="Close modal"
                  data-micromodal-close=""
                />
              </header>
              <main className="modal__content" id="editor-modal-content">
                <textarea
                  style={{ resize: "vertical", width: 400, height: 150 }}
                  id="editor-modal-text-area"
                  defaultValue={""}
                />
              </main>
              <footer className="modal__footer">
                <button
                  id="editor-modal-save-button"
                  className="modal__btn modal__btn-primary"
                >
                  Save
                </button>
                <button
                  className="modal__btn"
                  data-micromodal-close=""
                  aria-label="Close this dialog window"
                >
                  Close
                </button>
              </footer>
            </div>
          </div>
        </div>
        <div
          className="modal micromodal-slide"
          id="task-list-modal"
          aria-hidden="true"
        >
          <div
            className="modal__overlay"
            tabIndex={-1}
            data-micromodal-close=""
          >
            <div
              className="modal__container"
              role="dialog"
              aria-modal="true"
              aria-labelledby="task-list-modal-title"
            >
              <header className="modal__header">
                <h2 className="modal__title" id="task-list-modal-title">
                  Micromodal
                </h2>
                <button
                  className="modal__close"
                  aria-label="Close modal"
                  data-micromodal-close=""
                />
              </header>
              <main
                className="modal__content"
                id="task-list-modal-content"
              ></main>
              <footer className="modal__footer">
                <button
                  id="task-list-modal-save-button"
                  className="modal__btn modal__btn-primary"
                >
                  Save
                </button>
                <button
                  className="modal__btn"
                  data-micromodal-close=""
                  aria-label="Close this dialog window"
                >
                  Close
                </button>
              </footer>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default BpmnModelerComponent;
