import { isObject } from "min-dash";
import { create, appendTo, attr } from "tiny-svg";

/**
 * Convert the given bounds to a { top, left, bottom, right } descriptor.
 *
 * @param {Bounds|Point} bounds
 *
 * @return {Object}
 */
export function asTRBL(bounds) {
  return {
    top: bounds.y,
    right: bounds.x + (bounds.width || 0),
    bottom: bounds.y + (bounds.height || 0),
    left: bounds.x,
  };
}

export const colorObj = {
  invalid: "#ed6e7d",
  warning: "#ffc01c",
  valid: "#003366",
};

// orientation utils //////////////////////////////

/**
 * Get orientation of the given rectangle with respect to
 * the reference rectangle.
 *
 * A padding (positive or negative) may be passed to influence
 * horizontal / vertical orientation and intersection.
 *
 * @param {Bounds} rect
 * @param {Bounds} reference
 * @param {Point|Number} padding
 *
 * @return {String} the orientation; one of top, top-left, left, ..., bottom, right or intersect.
 */
export function getOrientation(rect, reference, padding) {
  padding = padding || 0;

  // make sure we can use an object, too
  // for individual { x, y } padding
  if (!isObject(padding)) {
    padding = { x: padding, y: padding };
  }

  var rectOrientation = asTRBL(rect),
    referenceOrientation = asTRBL(reference);

  var top = rectOrientation.bottom + padding.y <= referenceOrientation.top,
    right = rectOrientation.left - padding.x >= referenceOrientation.right,
    bottom = rectOrientation.top - padding.y >= referenceOrientation.bottom,
    left = rectOrientation.right + padding.x <= referenceOrientation.left;

  var vertical = top ? "top" : bottom ? "bottom" : null,
    horizontal = left ? "left" : right ? "right" : null;

  if (horizontal && vertical) {
    return vertical + "-" + horizontal;
  } else {
    return horizontal || vertical || "intersect";
  }
}

export function getOrientationOnAxis(rect, reference, padding) {
  padding = padding || 0;

  // make sure we can use an object, too
  // for individual { x, y } padding
  if (!isObject(padding)) {
    padding = { x: padding, y: padding };
  }

  var rectOrientation = asTRBL(rect),
    referenceOrientation = asTRBL(reference);

  var top = rectOrientation.bottom + padding.y === referenceOrientation.top,
    right = rectOrientation.left - padding.x === referenceOrientation.right,
    bottom = rectOrientation.top - padding.y === referenceOrientation.bottom,
    left = rectOrientation.right + padding.x === referenceOrientation.left;

  var vertical = top ? "top" : bottom ? "bottom" : null,
    horizontal = left ? "left" : right ? "right" : null;

  if (horizontal && vertical) {
    return vertical + "-" + horizontal;
  } else {
    return horizontal || vertical || "intersect";
  }
}

const halfRectagleShape = (type = "left", color = "#003366") => {
  // Create an SVG element
  const svg = create("svg");
  attr(svg, {
    width: 20,
    height: 20,
  });
  if (type === "top") {
    attr(svg, {
      style: "rotate: 90deg",
    });
  } else if (type === "right") {
    attr(svg, {
      style: "rotate: 180deg",
    });
  } else if (type === "bottom") {
    attr(svg, {
      style: "rotate: 270deg",
    });
  }
  attr(svg, {
    class: `half-arrow-svg-${type}`,
  });

  // Create a half-rectangle shape with two lines
  const halfRect = create("path");
  attr(halfRect, {
    d: "M 13 18 L 5 10 L 13 2",
    fill: "none",
    stroke: color,
    "stroke-width": 3,
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
  });

  // Append the half-rectangle shape to the SVG element
  appendTo(halfRect, svg);

  return svg;
};

const quadShape = (type = "left", color = "#003366") => {
  // Create an SVG element
  //svg height can be 0 to 20
  // svg width can be 3 to 23
  const svg = create("svg");
  attr(svg, {
    width: "100%",
    height: "100%",
  });
  if (type === "top") {
    attr(svg, {
      style: "rotate: 135deg",
    });
  } else if (type === "right") {
    attr(svg, {
      style: "rotate: 224deg",
    });
  } else if (type === "bottom") {
    attr(svg, {
      style: "rotate: 314deg",
    });
  } else {
    attr(svg, {
      style: "rotate: 45deg",
    });
  }
  attr(svg, {
    class: `quad-svg-${type}`,
  });

  // Create a half-rectangle shape with two lines
  const quad = create("path");
  attr(quad, {
    d: "M2,2 Q4,16 18,18",
    fill: "none",
    stroke: color,
    "stroke-width": 3,
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
  });

  // Append the half-rectangle shape to the SVG element
  appendTo(quad, svg);

  return svg;
};

const halfCurveShape = (type = "left", color = "#003366") => {
  // Create an SVG element
  //svg height can be 0 to 20
  // svg width can be 3 to 23
  const svg = create("svg");
  attr(svg, {
    width: 5,
    height: 40,
  });
  if (type === "top") {
    attr(svg, {
      style: "rotate: 93deg",
    });
  } else if (type === "right") {
    attr(svg, {
      style: "rotate: 183deg",
    });
  } else if (type === "bottom") {
    attr(svg, {
      style: "rotate: 271deg",
    });
  }
  attr(svg, {
    class: `curve-svg-${type}`,
  });

  // Create a circle element
  const circle = create("circle");
  attr(circle, {
    cx: 22, // Center x-coordinate
    cy: 22, // Center y-coordinate
    r: 20, // Radius
    fill: "none",
    stroke: color,
    "stroke-width": 2,
  });

  // Append the circle to the SVG element
  appendTo(circle, svg);

  return svg;
};

const halfArcShape = (type = "left", color = "#003366") => {
  // Create an SVG element
  //svg height can be 0 to 20
  // svg width can be 3 to 23
  const svg = create("svg");
  attr(svg, {
    width: 5,
    height: 100,
  });
  if (type === "top") {
    attr(svg, {
      style: "rotate: 91deg",
    });
  } else if (type === "right") {
    attr(svg, {
      style: "rotate: 183deg",
    });
  } else if (type === "bottom") {
    attr(svg, {
      style: "rotate: 269deg",
    });
  }
  attr(svg, {
    class: `curve-svg-${type}`,
  });

  // Create a circle element
  const circle = create("circle");
  attr(circle, {
    cx: 52, // Center x-coordinate
    cy: 52, // Center y-coordinate
    r: 50, // Radius
    fill: "none",
    stroke: color,
    "stroke-width": 3,
  });

  // Append the circle to the SVG element
  appendTo(circle, svg);

  return svg;
};

function ArrowWithCircleSVG(type = "right", color = "#003366") {
  // Create the SVG element
  const svgNS = "http://www.w3.org/2000/svg";
  const svg = document.createElementNS(svgNS, "svg");
  svg.setAttribute("width", "14");
  svg.setAttribute("height", "14");
  svg.setAttribute("viewBox", "0 0 14 14");

  if (type === "top") {
    attr(svg, {
      style: "rotate: 270deg",
    });
  } else if (type === "left") {
    attr(svg, {
      style: "rotate: 180deg",
    });
  } else if (type === "bottom") {
    attr(svg, {
      style: "rotate: 90deg",
    });
  }
  attr(svg, {
    class: `connection-start-svg-${type}`,
  });

  // Create the circle element
  const circle = document.createElementNS(svgNS, "circle");
  circle.setAttribute("cx", "7");
  circle.setAttribute("cy", "7");
  circle.setAttribute("r", "7");
  circle.setAttribute("fill", color);

  // Create the arrow element
  const arrow = document.createElementNS(svgNS, "path");
  arrow.setAttribute("d", "M 3 7 L 11 7 M 9 9 L 11 7 L 9 5");
  arrow.setAttribute("stroke", "white");
  arrow.setAttribute("stroke-width", "1");
  arrow.setAttribute("fill", "none");
  arrow.setAttribute("stroke-linecap", "round");
  arrow.setAttribute("stroke-linejoin", "round");

  // Append the elements to the SVG
  svg.appendChild(circle);
  svg.appendChild(arrow);

  return svg;
}

export function renderArrowWithCircle(
  shape,
  position,
  width,
  height,
  type,
  color
) {
  const divElem = document.createElement("div");
  divElem.style = `width: ${width}px; height: ${height}px;`;
  divElem.classList.add("connection-start-parent");
  divElem.append(ArrowWithCircleSVG(type, color));
  this._overlays.add(shape, "transaction-boundaries", {
    position: position,
    html: divElem,
  });
}

export function renderStraigthLineShape(
  shape,
  position,
  width,
  height,
  type,
  color
) {
  const divElem = document.createElement("div");
  divElem.style = `width: ${
    width + 1
  }px; height: ${height}px; background: ${color}; border-radius: 10px`;
  divElem.classList.add(`line-connector-parent-${type}`);
  this._overlays.add(shape, "transaction-boundaries", {
    position: position,
    html: divElem,
  });
}

export function renderRectangleShape(
  shape,
  position,
  width,
  height,
  type,
  color
) {
  const divElem = document.createElement("div");
  divElem.style = `width: ${width}px; height: ${height}px;`;
  divElem.classList.add("rect-connector-parent");
  divElem.append(halfRectagleShape(type, color));
  this._overlays.add(shape, "transaction-boundaries", {
    position: position,
    html: divElem,
  });
}

export function renderHalfCurveShape(
  shape,
  position,
  width,
  height,
  type,
  color
) {
  const divElem = document.createElement("div");
  divElem.style = `width: 20px; height: 20px;`;
  divElem.classList.add("quad-connector-parent");
  divElem.append(quadShape(type, color));
  this._overlays.add(shape, "transaction-boundaries", {
    position: position,
    html: divElem,
  });
}

export function renderHalfArcShape(
  shape,
  position,
  width,
  height,
  type,
  color
) {
  const divElem = document.createElement("div");
  divElem.style = `width: ${width}px; height: ${height}px;`;
  divElem.classList.add("arc-connector-parent");
  divElem.append(halfArcShape(type, color));
  this._overlays.add(shape, "transaction-boundaries", {
    position: position,
    html: divElem,
  });
}
