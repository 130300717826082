// customizeDiagramRenderer.js

import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";
import { is } from "bpmn-js/lib/util/ModelUtil";
import {
  append as svgAppend,
  create as svgCreate,
  attr as svgAttr,
} from "tiny-svg";

/**
 * CustomizeDiagramRenderer
 * 
 * A custom renderer for BPMN diagrams that adds custom classes to elements.
 *
 * @extends BaseRenderer
 */
export default class CustomizeDiagramRenderer extends BaseRenderer {
  /**
   * Constructor for CustomizeDiagramRenderer
   *
   * @param {Object} eventBus - The event bus service
   * @param {Object} bpmnRenderer - The BPMN renderer service
   * @param {Object} styles - The styles service
   */
  constructor(eventBus, bpmnRenderer, styles) {
    super(eventBus, 1500);
    this.bpmnRenderer = bpmnRenderer;
    this.styles = styles;
    this.eventBus = eventBus;
    this.attachCanRenderEventListener();
  }

  /**
   * Determine if the renderer can render the element.
   *
   * @param {Object} element - The BPMN element
   * @return {boolean} - Always returns true for custom elements
   */
  canRender(element) {
    return true;
  }

  /**
   * Draw the shape of the element and add custom classes.
   *
   * @param {Object} parentGfx - The parent graphics
   * @param {Object} element - The BPMN element
   * @return {Object} - The rendered shape
   */
  drawShape(parentGfx, element) {
    const shape = this.bpmnRenderer.drawShape(parentGfx, element);
    if (
      element.type.toLowerCase().includes("task") ||
      element.type.toLowerCase().includes("subprocess")
    ) {
      shape.classList.add("task-custom-class");
    } else if (element.type.toLowerCase().includes("gateway")) {
      shape.classList.add("gateway-custom-class");
    } else if (element.type.toLowerCase().includes("event")) {
      shape.classList.add("event-custom-class");
    } else if (element.type.toLowerCase().includes("object")) {
      shape.classList.add("object-custom-class");
    } else if (element.type.toLowerCase().includes("store")) {
      shape.classList.add("store-custom-class");
    }
    shape.classList.add(element?.errorType ? element?.errorType : "valid");
    return shape;
  }

  /**
   * Attach event listener to prevent waypoint updates.
   */
  attachCanRenderEventListener() {
    setTimeout(() => {
      this.eventBus.on("commandStack.connection.updateWaypoints.canExecute", 1500, function () {
        return false;
      });
    }, 1000);
  }
}

CustomizeDiagramRenderer.$inject = ["eventBus", "bpmnRenderer", "styles"];
