import builder from "xmlbuilder";

export default function jsonToBpmn(jsonData) {
  const process = jsonData.process;

  const bpmn = builder
    .create("bpmn:definitions", { encoding: "UTF-8" })
    .att("xmlns:bpmn", "http://www.omg.org/spec/BPMN/20100524/MODEL")
    .att("xmlns:bpmndi", "http://www.omg.org/spec/BPMN/20100524/DI")
    .att("xmlns:dc", "http://www.omg.org/spec/DD/20100524/DC")
    .att("xmlns:di", "http://www.omg.org/spec/DD/20100524/DI")
    .att("targetNamespace", "http://bpmn.io/schema/bpmn");

  const bpmnProcess = bpmn.ele("bpmn:process", {
    id: process.id,
    name: process.name,
    description: process.description,
  });

  process.tasks.forEach((task) => {
    bpmnProcess.ele("bpmn:task", {
      id: task.id,
      name: task.name,
      ...task,
    });
  });

  process.flows.forEach((flow) => {
    bpmnProcess.ele("bpmn:sequenceFlow", {
      id: flow.id,
      sourceRef: flow.source,
      targetRef: flow.target,
      ...flow,
    });
  });

  const bpmnDiagram = bpmn.ele("bpmndi:BPMNDiagram", { id: "BPMNDiagram_1" });
  const bpmnPlane = bpmnDiagram.ele("bpmndi:BPMNPlane", {
    id: "BPMNPlane_1",
    bpmnElement: process.id,
    description: process.description,
  });

  // Add graphical representation for tasks and flows (dummy positions)
  process.tasks.forEach((task, index) => {
    const x = 150;
    const y = 100 + index * 100;
    bpmnPlane
      .ele("bpmndi:BPMNShape", {
        id: `BPMNShape_${task.id}`,
        bpmnElement: task.id,
      })
      .ele("dc:Bounds", {
        x,
        y,
        width: 100,
        height: 80,
      });
  });

  process.flows.forEach((flow) => {
    bpmnPlane
      .ele("bpmndi:BPMNEdge", {
        id: `BPMNEdge_${flow.id}`,
        bpmnElement: flow.id,
      })
      .ele("di:waypoint", { x: 150, y: 100 })
      .ele("di:waypoint", { x: 150, y: 200 });
  });

  return bpmn.end({ pretty: true });
}
