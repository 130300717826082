import BpmnModelerComponent from "BPMN/BpmnModelerComponent";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  getIndividualQuestionnaire,
  createQuestionnaires,
} from "Redux-Store/Questionnaires/QuestionnairesThunk";
import { componentType, status } from "_constants";
import jsonToBpmn from "../../BPMN/jsonToBpmn";
import { Description } from "@mui/icons-material";

// const jsonData = {
//   process: {
//     id: "Process_1",
//     name: "Example Process",
//     tasks: [
//       { id: "Task_1", name: "Start Task" },
//       { id: "Task_2", name: "Intermediate Task" },
//       { id: "Task_3", name: "End Task" },
//     ],
//     flows: [
//       { id: "Flow_1", source: "Task_1", target: "Task_2" },
//       { id: "Flow_2", source: "Task_2", target: "Task_3" },
//     ],
//   },
// };

class BPMNView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bpmnViewData: null,
      questions: [],
      allDetails: {},
    };
  }
  componentDidMount() {
    const { match } = this.props;

    if (match && match.params && match.params.id) {
      this.setState({
        questionnaireId: match.params.id,
      });
      this.props.getIndividualQuestionnaire({
        id: match.params.id,
      });
    } else {
      this.props.history.push("/postlogin/questionnairelist");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.individualQuestionnaireData.status !==
        this.props.individualQuestionnaireData.status &&
      this.props.individualQuestionnaireData.status === status.SUCCESS
    ) {
      const questionnaire = this.props.individualQuestionnaireData.data || {};
      if (questionnaire && questionnaire.surveys) {
        const { questions, surveyName, surveyDescription } =
          questionnaire.surveys;
        this.setState({ questions, allDetails: questionnaire.surveys });
        this.manipulateData(questions, surveyName, surveyDescription);
      }
    }
  }

  manipulateData = async (questions, surveyName, surveyDescription) => {
    let bpmnViewData = null;
    const { match } = this.props;

    if (questions?.length && match.params.id) {
      bpmnViewData = {
        process: {
          id: "Survey_0",
          name: surveyName,
          description: surveyDescription,
          tasks: [],
          flows: [],
        },
      };

      questions.forEach((question, index) => {
        let opts = question.opts;
        let measurablesOpts = [];
        let samplesOpts = [];

        if (componentType.GRID === question.type) {
          opts = [];
          measurablesOpts = question.opts.measurables;
          samplesOpts = question.opts.samples;
        } else if (componentType.RATE === question.type) {
          opts = question.opts?.range ? question.opts?.range : [];
        }

        bpmnViewData.process.tasks.push({
          id: `Question_${question._id}`,
          name: question.question,
          description: question.description,
          opts,
          type: question.type,
          measurablesOpts,
          samplesOpts,
          ...question.details,
        });
        bpmnViewData.process.flows.push({
          id: `Flow_${question._id}`,
          source: `Question_${question._id}`,
          target: `Question_${questions[index + 1]?._id}`,
          description: question.description,
          type: question.type,
          measurablesOpts,
          samplesOpts,
          opts,
          name: question.question,
        });
      });

      this.setState({ bpmnViewData: jsonToBpmn(bpmnViewData) });
    }
  };
  xmlToJsonData = (obj) => {
    let {
      questions,
      allDetails: {
        type,
        status,
        surveyName,
        styleDetails,
        surveyDescription,
        images,
        isSkipOutro,
        outro,
      },
    } = this.state;
    questions = JSON.parse(JSON.stringify(questions));
    const { match } = this.props;
    questions = questions.map((question, index) => {
      if (obj[`Question_${question._id}`]) {
        question["showNext"] = obj[`Question_${question._id}`]["showNext"];
      }
      return question;
    });
    this.props.createQuestionnaires({
      type,
      status,
      surveyName,
      styleDetails,
      surveyDescription,
      images,
      isSkipOutro,
      outro,
      questions,
      isEdit: 1,
      questionnaireId: match.params.id,
    });
  };
  render() {
    let { bpmnViewData } = this.state;
    return (
      <div>
        {bpmnViewData ? (
          <BpmnModelerComponent
            data={bpmnViewData}
            xmlToJsonData={(obj) => this.xmlToJsonData(obj)}
            isLoading={
              this.props.createQuestionnairesData?.status === status.IN_PROGRESS
            }
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { individualQuestionnaireData, createQuestionnairesData } =
    state.surveys;

  return { individualQuestionnaireData, createQuestionnairesData };
}
const mapDispatchToProps = {
  getIndividualQuestionnaire,
  createQuestionnaires,
};
const connectedBPMNView = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(BPMNView)
);
export default connectedBPMNView;
