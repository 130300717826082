// index.js

import CustomizeDiagramRenderer from "./customizeDiagramRenderer";

/**
 * Module configuration for custom diagram renderer.
 */
export default {
  __init__: ["customizeDiagramRenderer"],
  customizeDiagramRenderer: ["type", CustomizeDiagramRenderer],
};
