import React, { Component } from "react";
import "../../_assets/scss/respondentsTearmsAndConditons.scss";

class RespondentsTearmsAndConditons extends Component {
  render() {
    return (
      <div className="termsAndConditions-container">
        <div className="termsAndConditions-inner-container">
          <h2 className="mb-4">Terms and Conditions for Respondents Portal          </h2>
          <p>Effective Date: September 30, 2024</p>
          <p>
            Welcome to SurvHaNa, a platform that connects organizations with
            respondents for targeted surveys. By using the Respondents Portal
            ("Portal") and participating in surveys, you agree to these Terms
            and Conditions ("Terms"). If you do not agree with these Terms,
            please do not use the Portal.
          </p>
          <h3>1. Definitions</h3>
          <ul>
            <li>
              <strong>SurvHaNa:</strong> Refers to SurvHaNa, a platform for
              targeted surveys.
            </li>
            <li>
              <strong>Respondent:</strong> Any individual who registers on the
              SurvHaNa Respondents Portal to participate in surveys.
            </li>
            <li>
              <strong>Organization:</strong> A company or entity that creates
              and publishes surveys on SurvHaNa for respondent participation.
            </li>
            <li>
              <strong>Portal:</strong> The web and mobile interface provided by
              SurvHaNa where respondents can register, view, and participate in
              surveys
            </li>
          </ul>
          <h3>2. Registration and Account Creation</h3>
          <div className="d-block terms-content mb-3">
            <label>2.1 Eligibility:</label>
            <p>
              To register as a respondent, you must be at least 18 years old and
              legally able to enter into these Terms. You agree to provide
              accurate and complete information during registration and to keep
              your information updated.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>2.2 Account Security:</label>
            <p>
              You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account. You must notify us immediately of any unauthorized use of
              your account.
            </p>
          </div>
          <h3>3. Participation in Surveys</h3>
          <div className="d-block terms-content mb-3">
            <label>3.1 Survey Invitations:</label>
            <p>
              As a respondent, you may receive notifications for surveys based
              on the criteria set by the organization (e.g., age, gender,
              location, etc.). You are not obligated to participate and may
              choose which surveys to complete.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>3.2 Accuracy of Responses:</label>
            <p>
              By participating in a survey, you agree to provide accurate,
              truthful, and honest answers. Submitting false or misleading
              information may result in the termination of your account and
              forfeiture of any rewards.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>3.3 Survey Completion:</label>
            <p>
              Surveys must be completed in good faith and in accordance with the
              specific guidelines provided for each survey. SurvHaNa and the
              survey organization reserve the right to reject incomplete or
              insincere responses.
            </p>
          </div>
          <h3>4. Rewards for Participation</h3>
          <div className="d-block terms-content mb-3">
            <label>4.1 Reward System:</label>
            <p>
              You may be eligible to receive rewards (e.g., monetary
              compensation, gift cards) for completing certain surveys, provided
              your responses meet the criteria set by the organization.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>4.2 Conditions for Receiving Rewards:</label>
            <p>
              Rewards will only be distributed if your responses are deemed
              legitimate and in compliance with the survey requirements.
              Fraudulent or insincere responses may disqualify you from
              receiving rewards.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>4.3 Distribution of Rewards:</label>
            <p>
              Rewards will be issued in accordance with the terms specified by
              SurvHaNa. The distribution process and timelines may vary
              depending on the type of reward.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>4.4 Taxes and Obligations:</label>
            <p>
              You are responsible for reporting and paying any taxes or other
              obligations related to the rewards you receive.
            </p>
          </div>
          <h3>5. Data Collection and Use</h3>
          <div className="d-block terms-content mb-3">
            <label>5.1 Personal Data:</label>
            <p>
              When you register and participate in surveys, we collect and
              process certain personal information (e.g., name, gender, age,
              income). By using the Portal, you agree to the collection and
              processing of this data as outlined in our Privacy Policy.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>5.2 Anonymized Data:</label>
            <p>
              We may share anonymized or aggregated survey data with
              organizations for analytical purposes. Your personally
              identifiable information will not be disclosed without your
              consent.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>5.3 Data Security:</label>
            <p>
              SurvHaNa takes appropriate security measures to protect your
              personal data. However, you acknowledge that no method of
              electronic transmission or storage is completely secure, and we
              cannot guarantee absolute security.
            </p>
          </div>
          <h3>6. Acceptable Use</h3>
          <div className="d-block terms-content mb-3">
            <label>6.1 Compliance with Laws:</label>
            <p>
              You agree to use the Portal in compliance with all applicable laws
              and regulations.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>6.2 Prohibited Conduct:</label>
            <p>You agree not to:</p>
            <ul>
              <li>Submit false or misleading information.</li>
              <li>Engage in fraudulent or unethical behavior.</li>
              <li>
                Distribute viruses, malware, or other harmful software through
                the Portal
              </li>
              <li>
                Attempt to gain unauthorized access to any part of the Portal or
                the systems that support it.
              </li>
            </ul>
          </div>
          <div className="d-block terms-content mb-3">
            <label>6.3 Termination of Access:</label>
            <p>
              SurvHaNa reserves the right to suspend or terminate your account
              for violations of these Terms or for any conduct that we deem
              inappropriate.
            </p>
          </div>
          <h3>7. Intellectual Property</h3>
          <div className="d-block terms-content mb-3">
            <label>7.1 Ownership:</label>
            <p>
              All content on the Portal, including text, images, logos, and
              software, is owned by or licensed to SurvHaNa and is protected by
              copyright, trademark, and other intellectual property laws.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>7.2 Limited License:</label>
            <p>
              You are granted a limited, non-exclusive, non-transferable license
              to access and use the Portal for personal, non-commercial
              purposes. Any unauthorized use of the Portal may result in the
              termination of this license.
            </p>
          </div>
          <h3>8. Disclaimer of Warranties</h3>
          <div className="d-block terms-content mb-3">
            <label>8.1 As-Is Basis:</label>
            <p>
              The Portal is provided on an "as-is" and "as-available" basis.
              SurvHaNa makes no warranties or representations regarding the
              accuracy, completeness, or reliability of the Portal or the
              surveys.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>8.2 No Guarantee of Survey Availability:</label>
            <p>
              SurvHaNa does not guarantee that you will receive a certain number
              of survey invitations or rewards. Survey availability depends on
              the needs of the organizations using our platform.
            </p>
          </div>
          <h3>9. Limitation of Liability</h3>
          <div className="d-block terms-content mb-3">
            <label>9.1 Exclusion of Certain Damages:</label>
            <p>
              To the maximum extent permitted by law, SurvHaNa is not liable for
              any indirect, incidental, special, or consequential damages,
              including lost profits or data, resulting from your use of or
              inability to use the Portal.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>9.2 Maximum Liability:</label>
            <p>
              SurvHaNa's total liability to you for any claims arising out of or
              related to your use of the Portal shall not exceed the amount of
              any rewards due to you for completed surveys in the 12 months
              preceding the claim.
            </p>
          </div>
          <h3>10. Changes to These Terms</h3>
          <p>
            SurvHaNa reserves the right to modify these Terms at any time. We
            will notify you of any changes by posting the updated Terms on the
            Portal. Your continued use of the Portal after the changes take
            effect constitutes your acceptance of the revised Terms.
          </p>
          <h3>11. Governing Law and Dispute Resolution</h3>
          <div className="d-block terms-content mb-3">
            <label>11.1 Governing Law:</label>
            <p>
              These Terms are governed by and construed in accordance with the
              laws of India, without regard to its conflict of law principles.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>11.2 Dispute Resolution:</label>
            <p>
              Any disputes arising from these Terms will be resolved through
              binding arbitration under the rules applicable in [Insert
              Location]. You agree to submit to the jurisdiction of the courts
              in [Insert Location].
            </p>
          </div>
          <h3>12. Contact Information</h3>
          <p>
            If you have any questions about these Terms, please contact us at:
          </p>
          <ul className="border-bottom pb-4">
            <li>
              <span> Email : </span>
              <a href="mailto: jasmin@survhana.com">jasmin@survhana.com</a>
            </li>
            <li>
              <span>Website : </span>
              <a href="https://www.survhana.com/organization">
                www.survhana.com{" "}
              </a>
            </li>
          </ul>
          <strong>
            By using the SurvHaNa Respondents Portal, you acknowledge that you
            have read, understood, and agree to these Terms and Conditions.
          </strong>
        </div>
      </div>
    );
  }
}

export default RespondentsTearmsAndConditons;
