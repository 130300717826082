import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (organizationUser) => {
    try {
      const url = `organization/auth/profile`;
      const response = await postLoginService.post(url, { organizationUser });
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (params) => {
    try {
      const url = `organization/auth/editprofile`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
