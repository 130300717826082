// ExtendedPropertyProvider.js

import customGroupRender, {
  TabComponent,
  IdInput,
  NameInput,
  DescriptionInput,
} from "./parts/Components";
import { is } from "bpmn-js/lib/util/ModelUtil";
import responseJson from "../json/rest-response.json";
import {
  createTaskParameter,
  createClarityParameter,
  nextId,
} from "./parts/utils";
import { CheckboxEntry } from "@bpmn-io/properties-panel";
import CreateCustomGroup from "./parts/CustomGroup";
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";
import { useState, useEffect } from "@bpmn-io/properties-panel/preact/hooks";
import { html } from "htm/preact";
import { render } from "@bpmn-io/properties-panel/preact";
import MicroModal from "micromodal";
import { BPMN_VIEW_TYPE, componentType } from "_constants";
import { query as domQuery } from "min-dom";
import { alert } from "_utilities";
const LOW_PRIORITY = 500;
let isChecked = false;
let isResizeListenerAdded = false;
let isMouseDown = false;
let flowDirections = {};
/**
 * MagicPropertiesProvider
 *
 * A provider for adding custom properties to the BPMN properties panel.
 *
 * @param {Object} propertiesPanel - The properties panel service
 * @param {Object} injector - The injector service
 * @param {Object} eventBus - The event bus service
 */
export default function MagicPropertiesProvider(
  propertiesPanel,
  injector,
  eventBus
) {
  const elementRegistry = injector.get("elementRegistry");
  const modeling = injector.get("modeling");
  /**
   * Get the property groups for the given element.
   *
   * @param {Object} element - The BPMN element to get property groups for
   * @return {Function} - A function that takes groups and returns the updated groups
   */

  function CustomExecuteFlow(props) {
    const {
      id,
      measurablesOpts = [],
      samplesOpts = [],
      opts = [],
      type = "",
      element,
      executeFlow,
      injector,
    } = props;

    function getOptions() {
      let strToArrOpts = opts?.length ? opts?.split(",") : [];
      let strToArrMeasurablesOpts = measurablesOpts?.length
        ? measurablesOpts?.split(",")
        : [];
      let strToArrSamplesOpts = samplesOpts?.length
        ? samplesOpts?.split(",")
        : [];

      const commandStack = injector.get("commandStack");
      const translate = injector.get("translate");

      const getValue = () => {
        return element.businessObject.get("selectedValue") || "";
      };

      const setValue = (option, value) => {
        if (element.businessObject) {
          commandStack.execute("element.updateModdleProperties", {
            element,
            moddleElement: element.businessObject,
            properties: {
              selectedValue: { ...getValue(), [option]: value },
            },
          });
        }
      };
      if (
        strToArrOpts?.length &&
        ![
          componentType.LINESCALE,
          componentType.RATE,
          componentType.GRID,
        ].includes(type)
      ) {
        return html`<ul>
          ${strToArrOpts.map((opt, index) => {
            return html`<input
                type="checkbox"
                checked=${getValue() ? getValue()[opt] : false}
                onChange=${(e) => {
                  setValue(opt, e.target.checked);
                }}
              /><label>${opt}</label><br />`;
          })}
        </ul>`;
      } else if (
        strToArrMeasurablesOpts?.length &&
        strToArrSamplesOpts?.length &&
        type === componentType.GRID
      ) {
        let samples = samplesOpts?.length ? samplesOpts.split(",") : [];

        return html` <p>Measurables -</p>
          <ul>
            ${strToArrMeasurablesOpts.map((opt) => {
              return html`<input
                  type="checkbox"
                  checked=${getValue() ? getValue()[opt] : false}
                  onChange=${(e) => {
                    setValue(opt, e.target.checked);
                  }}
                /><label>${opt}</label><br />`;
            })}
          </ul>
          <p>Samples -</p>
          <ul>
            ${samples.map((opt) => {
              return html`<input
                  type="checkbox"
                  checked=${getValue() ? getValue()[opt] : false}
                  onChange=${(e) => {
                    setValue(opt, e.target.checked);
                  }}
                /><label>${opt}</label> <br />`;
            })}
          </ul>`;
      } else if ([componentType.LINESCALE, componentType.RATE].includes(type)) {
        return html`<input
            type="number"
            value=${getValue() ? getValue()[`${type}_from`] : 0}
            onChange=${(e) => {
              setValue(`${type}_from`, e.target.value);
            }}
            min="0"
            max=${componentType.RATE === type ? opts[0] : 100}
          />
          <label>To </label>
          <input
            type="number"
            value=${getValue() ? getValue()[`${type}_to`] : 0}
            onChange=${(e) => {
              setValue(`${type}_to`, e.target.value);
            }}
            min="0"
            max=${componentType.RATE === type ? opts[0] : 100}
          /><br />`;
      }
    }

    return html`
      <div class="custom-property-entry">
        <label>When user select particular flow execute</label>
        ${getOptions()}
      </div>
    `;
  }
  this.getGroups = function (element) {
    return function (groups) {
      const sourceElement = element.businessObject.sourceRef;

      let elementDataType = [
        BPMN_VIEW_TYPE.NAME,
        BPMN_VIEW_TYPE.DESCRIPTION,
        BPMN_VIEW_TYPE.IS_MANDATORY,
        BPMN_VIEW_TYPE.MANDATORY_MESSAGE,
        BPMN_VIEW_TYPE.VALIDATION_MESSAGE,
        BPMN_VIEW_TYPE.VALIDATION_REGEX,
        BPMN_VIEW_TYPE.OPTS,
      ];

      let lineEventTrigger = document.getElementsByClassName("djs-context-pad");
      if (sourceElement) {
        elementDataType = [
          BPMN_VIEW_TYPE.NAME,
          BPMN_VIEW_TYPE.DESCRIPTION,
          BPMN_VIEW_TYPE.OPTS,
          BPMN_VIEW_TYPE.EXECUTE_FLOW,
        ];

        lineEventTrigger?.[0]?.classList?.add("flow-line-event");
      } else {
        lineEventTrigger?.classList?.remove("flow-line-event");
      }

      if (groups.length) {
        return elementDataType.map((typeName) => {
          if (typeName === BPMN_VIEW_TYPE.DESCRIPTION) {
            return {
              id: BPMN_VIEW_TYPE.DESCRIPTION,
              component: CustomDescription,
              isEdited: () => false,
              description: sourceElement?.$attrs
                ? sourceElement?.$attrs?.description
                : element.businessObject.get(BPMN_VIEW_TYPE.DESCRIPTION),
            };
          } else if (typeName === BPMN_VIEW_TYPE.NAME) {
            return {
              id: BPMN_VIEW_TYPE.NAME,
              component: CustomName,
              isEdited: () => false,
              name: sourceElement
                ? sourceElement.name
                : element.businessObject.get(BPMN_VIEW_TYPE.NAME),
            };
          } else if (typeName === BPMN_VIEW_TYPE.IS_MANDATORY) {
            return {
              id: BPMN_VIEW_TYPE.IS_MANDATORY,
              component: CustomIsMandatory,
              isEdited: () => false,
              isMandatory: element.businessObject.get(
                BPMN_VIEW_TYPE.IS_MANDATORY
              ),
            };
          } else if (typeName === BPMN_VIEW_TYPE.MANDATORY_MESSAGE) {
            return {
              id: BPMN_VIEW_TYPE.MANDATORY_MESSAGE,
              component: CustomMandatoryMessage,
              isEdited: () => false,
              mandatoryMessage: element.businessObject.get(
                BPMN_VIEW_TYPE.MANDATORY_MESSAGE
              ),
            };
          } else if (typeName === BPMN_VIEW_TYPE.VALIDATION_MESSAGE) {
            return {
              id: BPMN_VIEW_TYPE.VALIDATION_MESSAGE,
              component: CustomValidationMessage,
              isEdited: () => false,
              validationMessage: element.businessObject.get(
                BPMN_VIEW_TYPE.VALIDATION_MESSAGE
              ),
            };
          } else if (typeName === BPMN_VIEW_TYPE.VALIDATION_REGEX) {
            return {
              id: BPMN_VIEW_TYPE.VALIDATION_REGEX,
              component: CustomValidationRegex,
              isEdited: () => false,
              validationRegex: element.businessObject.get(
                BPMN_VIEW_TYPE.VALIDATION_REGEX
              ),
            };
          } else if (typeName === BPMN_VIEW_TYPE.OPTS) {
            let opts = element.businessObject.get(BPMN_VIEW_TYPE.OPTS);
            let measurablesOpts = element.businessObject.get(
              BPMN_VIEW_TYPE.MEASURABLES_OPTS
            );
            let samplesOpts = element.businessObject.get(
              BPMN_VIEW_TYPE.SAMPLES_OPTS
            );

            if (sourceElement) {
              opts = sourceElement?.$attrs?.opts;
              measurablesOpts = sourceElement?.$attrs?.measurablesOpts;
              samplesOpts = sourceElement?.$attrs?.samplesOpts;
            }

            return {
              id: BPMN_VIEW_TYPE.OPTS,
              component: CustomOptions,
              isEdited: () => false,
              opts,
              measurablesOpts,
              samplesOpts,
              type: sourceElement
                ? sourceElement.$attrs?.type
                : element.businessObject.get("type"),
            };
          } else if (typeName === BPMN_VIEW_TYPE.EXECUTE_FLOW) {
            let opts = element.businessObject.get(BPMN_VIEW_TYPE.OPTS);
            let measurablesOpts = element.businessObject.get(
              BPMN_VIEW_TYPE.MEASURABLES_OPTS
            );
            let samplesOpts = element.businessObject.get(
              BPMN_VIEW_TYPE.SAMPLES_OPTS
            );

            if (sourceElement) {
              opts = sourceElement?.$attrs?.opts;
              measurablesOpts = sourceElement?.$attrs?.measurablesOpts;
              samplesOpts = sourceElement?.$attrs?.samplesOpts;
            }
            return {
              id: element.businessObject.get("id"),
              component: CustomExecuteFlow,
              isEdited: () => false,
              opts,
              measurablesOpts,
              samplesOpts,
              type: sourceElement
                ? sourceElement.$attrs?.type
                : element.businessObject.get("type"),
              element,
              executeFlow: element.businessObject.get("executeFlow") || [],
              injector,
            };
          }
        });
      }

      return [];
    };
  };
  propertiesPanel.registerProvider(LOW_PRIORITY, this);
}
MagicPropertiesProvider.$inject = ["propertiesPanel", "injector", "eventBus"];

function CustomDescription(props) {
  const { description = "" } = props;

  return html`
    <div class="custom-property-entry">
      <label>Description</label>
      <input type="text" value=${description} onInput=${(e) => {}} readonly />
    </div>
  `;
}

function CustomName(props) {
  const { name = "" } = props;

  return html`
    <div class="custom-property-entry">
      <label>Question</label>
      <input type="text" value=${name} onInput=${(e) => {}} readonly />
    </div>
  `;
}

function CustomIsMandatory(props) {
  const { isMandatory = false } = props;

  return html`
    <div class="custom-property-entry">
      <label>Is Mandatory</label>
      <input
        type="checkbox"
        checked=${isMandatory}
        onInput=${(e) => {}}
        readonly
      />
    </div>
  `;
}

function CustomMandatoryMessage(props) {
  const { mandatoryMessage = "" } = props;

  return html`
    <div class="custom-property-entry">
      <label>Mandatory Message</label>
      <input
        type="text"
        value=${mandatoryMessage}
        onInput=${(e) => {}}
        readonly
      />
    </div>
  `;
}

function CustomValidationMessage(props) {
  const { validationMessage = "" } = props;

  return html`
    <div class="custom-property-entry">
      <label>Validation Message</label>
      <input
        type="text"
        value=${validationMessage}
        onInput=${(e) => {}}
        readonly
      />
    </div>
  `;
}

function CustomValidationRegex(props) {
  const { validationRegex = "" } = props;

  return html`
    <div class="custom-property-entry">
      <label>Validation Regex</label>
      <input
        type="text"
        value=${validationRegex}
        onInput=${(e) => {}}
        readonly
      />
    </div>
  `;
}

function CustomOptions(props) {
  const {
    measurablesOpts = [],
    samplesOpts = [],
    opts = [],
    type = "",
  } = props;

  function getOptions() {
    let strToArrOpts = opts?.length ? opts?.split(",") : [];

    if (strToArrOpts?.length && type !== componentType.GRID) {
      return html`<ul>
        ${strToArrOpts.map((opt) => {
          return html`<li>${opt}</li>`;
        })}
      </ul>`;
    } else if (type === componentType.GRID) {
      let measurables = measurablesOpts?.length
        ? measurablesOpts.split(",")
        : [];
      let samples = samplesOpts?.length ? samplesOpts.split(",") : [];

      return html` <p>Measurables -</p>
        <ul>
          ${measurables.map((opt) => {
            return html`<li>${opt}</li>`;
          })}
        </ul>
        <p>Samples -</p>
        <ul>
          ${samples.map((opt) => {
            return html`<li>${opt}</li>`;
          })}
        </ul>`;
    }
  }
  return html`
    <div class="custom-property-entry">
      <label>Options</label>
      ${getOptions()}
    </div>
  `;
}
