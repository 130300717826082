import React, { Component } from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { ValidationEngine, alert, commonFunctions } from "_utilities";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getUserProfile,
  updateUserProfile,
} from "Redux-Store/Profile/ProfileThunk";
import { connect } from "react-redux";
import { status } from "_constants";
import { Loading } from "_components";
// import "_assets/scss/profile.scss";
import "../../_assets/scss/login.scss";
import { CustomTextbox } from "_components";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocationCityIcon from "@mui/icons-material/LocationCity";

const styles = {
  button: {
    color: "#434bdf",
    fontSize: "15px",
    fontWeight: "500",
    textTransform: "uppercase",
    background: "#ffffff",
    borderRadius: "7px",
    width: "100%",
    height: "44px",
    textAlign: "center",
    borderColor: "#ffffff",
    marginTop: "9px",
    textTransform: "none",
  },
};

const validationSchema = {
  name: [
    {
      message: "Please enter  Name",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
};
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      company: "",
      email: "",
      isSubmitted: false,
    };
    this.userData = commonFunctions.getUserData();
  }
  componentDidMount() {
    this.props.getUserProfile(this.userData?.userDetails?._id);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.userProfileData.status !== prevProps.userProfileData.status
    ) {
      if (this.props.userProfileData.status === status.SUCCESS) {
        let response = this.props.userProfileData.data || {};
        if (response[0]) {
          let { name, email, organizationId } = response[0];
          this.setState({ name, email, company: organizationId?.name });
        }
      } else if (this.props.userProfileData.status === status.FAILURE) {
        alert.error(
          this.props.userProfileData?.data === "Rejected"
            ? "There is some internal server error."
            : this.props.userProfileData?.data
        );
      }
    }

    if (
      this.props.updatedProfileData.status !==
      prevProps.updatedProfileData.status
    ) {
      if (this.props.updatedProfileData.status === status.SUCCESS) {
        let response = this.props.updatedProfileData.data || {};

        if (response?.status) {
          let { name } = this.state;

          commonFunctions.setUserData({
            ...this.userData,
            userDetails: { ...this.userData.userDetails, name },
          });
          this.props.history.push("/postlogin/dashboard");
          alert.success(response?.message);
        } else {
          alert.error(response?.message);
        }
      } else if (this.props.updatedProfileData.status === status.FAILURE) {
        alert.error(
          this.props.updatedProfileData?.data === "Rejected"
            ? "There is some internal server error."
            : this.props.updatedProfileData?.data
        );
      }
    }
  };
  validateForm = () => {
    const { name } = this.state;
    const error = ValidationEngine.validate(validationSchema, { name });
    return error;
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState(
      {
        isSubmitted: true,
      },
      () => {
        const errors = this.validateForm();
        if (errors.isValid) {
          const { name } = this.state;
          this.props.updateUserProfile({
            name,
            organizationUser: this.userData?.userDetails?._id,
          });
        }
      }
    );
  };
  render() {
    const { name, email, company, isSubmitted } = this.state;
    const errorData = this.validateForm();
    let { userProfileData, updatedProfileData } = this.props;
    return (
      <div
        className="d-block w-100 login-main"
        style={{ marginTop: "-85px", height: "calc(100% + 85px)" }}
      >
        <div className="profile-container d-flex align-items-center justify-content-center h-100">
          <div className="d-block w-100 text-center">
            {userProfileData.status === status.IN_PROGRESS ? (
              <div
                className="w-100 text-center d-flex align-items-center justify-content-center"
                style={{ height: "83vh" }}
              >
                <Loading />
              </div>
            ) : (
              <div className="d-inline-block text-left signin-form">
                <form className="w-100" onSubmit={this.handleSubmit}>
                  <CustomTextbox
                    type={"text"}
                    containerClass="custom-input-container position-relative"
                    // labelClass="d-none"
                    inputClass="form-control"
                    label={"Name"}
                    htmlFor="name"
                    id="name"
                    name="name"
                    value={name}
                    icon={<PersonIcon />}
                    onChange={this.handleChange}
                    isValid={isSubmitted && errorData.name.isValid}
                    message={isSubmitted && errorData.name.message}
                  />

                  <CustomTextbox
                    type={"email"}
                    containerClass="custom-input-container position-relative"
                    // labelClass="d-none"
                    inputClass="form-control"
                    label={"Email"}
                    htmlFor="email"
                    id="email"
                    name="email"
                    value={email}
                    disabled={true}
                    icon={<EmailIcon />}
                  />
                  <CustomTextbox
                    type={"text"}
                    containerClass="custom-input-container position-relative"
                    // labelClass="d-none"
                    inputClass="form-control"
                    label={"Company"}
                    htmlFor="company"
                    id="company"
                    name="company"
                    value={company}
                    disabled={true}
                    icon={<LocationCityIcon />}
                  />
                  <div
                    id="bottom-wizard"
                    className="d-flex align-item-center justify-content-start "
                  >
                    <LoadingButton
                      className="dark-contained-btn"
                      variant="contained"
                      size="medium"
                      loading={updatedProfileData.status === status.IN_PROGRESS}
                      disabled={
                        updatedProfileData.status === status.IN_PROGRESS
                      }
                      type="submit"
                      onClick={this.handleSubmit}
                      style={styles.button}
                    >
                      {updatedProfileData.status !== status.IN_PROGRESS &&
                        "Update"}
                      {updatedProfileData.status === status.IN_PROGRESS && (
                        <></>
                      )}
                    </LoadingButton>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userProfileData, updatedProfileData } = state.profile;
  return {
    userProfileData,
    updatedProfileData,
  };
}

const mapDispatchToProps = {
  getUserProfile,
  updateUserProfile,
};

const connectedProfile = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default connectedProfile;
